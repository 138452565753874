<template>
  <div class="account-manage">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="text-left">我的活动</span>
      </div>
      <div class="flex between seach">
        <div>
          <div>活动状态</div>
          <el-radio-group v-model="sizeForm.type">
            <el-radio-button label="全部"></el-radio-button>
            <el-radio-button label="进行中"></el-radio-button>
            <el-radio-button label="已结束"></el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <div>活动日期</div>
          <el-radio-group v-model="sizeForm.date">
            <el-radio-button label="最新"></el-radio-button>
            <el-radio-button label="1-3月"></el-radio-button>
            <el-radio-button label="4-6月"></el-radio-button>
            <el-radio-button label="7-9月"></el-radio-button>
            <el-radio-button label="10-12月"></el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <div>搜索活动</div>
          <el-input
            type="text"
            v-model="sizeForm.input"
            placeholder="请输入活动名称"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
      </div>
      <div class="activity-list">
        <activityList :pageListTotal="pageListTotal" :pageSize="pageSize" :currentPage="currentPage" :activityList="activityList"></activityList>
      </div>
    </el-card>
  </div>
</template>

<script>
import { request } from "../../../network";
import { mapState } from "vuex";
import activityList from './components/activityList';
export default {
  components: {
    activityList
  },
  data() {
    return {
      sizeForm: {
        type: "全部",
        date: "最新",
        input: "",
      },
      pageListTotal: 0,
      pageSize: 10,
      currentPage: 1,
      activityList: JSON.parse(sessionStorage.getItem("topicList")),
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.text-left {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.box-card {
  margin-bottom: 20px;
}
/deep/.el-card__body {
  padding: 0 20px;
}
.seach {
  margin: 20px 0;
  > div {
    > div {
      margin-bottom: 10px;
    }
  }

}

</style>
